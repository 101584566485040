import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/bestpractice/bestpractice/node_modules/gatsby-theme-docz/src/base/Layout.js";
import APIDoc from "../../src/components/apidoc";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "helerm"
    }}>{`Helerm`}</h1>
    <p>{`Helsinki Electronic Records Management Classification System.`}</p>
    <ul>
      <li parentName="ul">{`API location: `}<a parentName="li" {...{
          "href": "https://api.hel.fi/helerm/v1"
        }}>{`api.hel.fi/helerm/v1`}</a></li>
    </ul>
    <h2 {...{
      "id": "documentation"
    }}>{`Documentation`}</h2>

    <APIDoc doc="https://raw.githubusercontent.com/City-of-Helsinki/helerm/master/openapi.yaml" mdxType="APIDoc" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      